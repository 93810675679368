var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          staticStyle: { "min-width": "40 rem" },
          attrs: {
            "no-fade": "",
            id: "modal-styling",
            size: "xl",
            title: _vm.getProperty("name"),
          },
          on: { hide: _vm.saveStyle },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _vm.editMode == "layer"
                    ? _c(
                        "span",
                        [
                          _vm._v(
                            "\n\t\t\t\t \t" +
                              _vm._s(_vm.myObject.get("name")) +
                              "\n\t\t\t\t\t"
                          ),
                          _vm.isVector
                            ? _c("b-badge", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.myObject.getSource().getFeatures()
                                        .length
                                    ) +
                                    " feature(s)"
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("b-badge", [
                            _vm._v(_vm._s(_vm.getProperty("type"))),
                          ]),
                          _vm._v(" "),
                          _c("b-badge", [
                            _vm._v(_vm._s(_vm.getProperty("user"))),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editMode == "feature"
                    ? _c("b-badge", [
                        _vm._v(
                          " " + _vm._s(_vm.myObject.getGeometry().getType())
                        ),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.isShown = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.isShown,
            callback: function ($$v) {
              _vm.isShown = $$v
            },
            expression: "isShown",
          },
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _vm.getProperty("id") === "29164"
                    ? _c(
                        "b-col",
                        { attrs: { lg: "7" } },
                        [
                          _c(
                            "b-card",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-for": "layerTimeBPRange",
                                    sm: "",
                                    label: "tijd in BP",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "range",
                                      id: "layerTimeBPRange",
                                      min: "0",
                                      max: "40000",
                                      step: "100",
                                    },
                                    on: { change: _vm.updateWMSParams },
                                    model: {
                                      value: _vm.myStyle.timeBP,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.myStyle, "timeBP", $$v)
                                      },
                                      expression: "myStyle.timeBP",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      "time BP: " + _vm._s(_vm.myStyle.timeBP)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { lg: "3" } },
                    [
                      _vm.getProperty("type") === "wms"
                        ? _c(
                            "b-card",
                            { attrs: { sm: "" } },
                            _vm._l(_vm.wmsLayers, function (item, index) {
                              return _c(
                                "b-button",
                                {
                                  key: item.id,
                                  attrs: {
                                    variant: "outline-info",
                                    size: "sm",
                                    item: item,
                                    index: index,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showLegend(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(item) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editMode == "layer"
                        ? _c(
                            "b-card",
                            { attrs: { sm: "" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-info",
                                    size: "sm",
                                  },
                                  on: { click: _vm.zoom },
                                },
                                [_vm._v("Zoom to extent")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-info",
                                    size: "sm",
                                  },
                                  on: { click: _vm.reload },
                                },
                                [_vm._v("Reload")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.modal-list-features",
                                      modifiers: {
                                        "modal-list-features": true,
                                      },
                                    },
                                  ],
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-info",
                                    size: "sm",
                                    title: "lijst met features weergeven",
                                  },
                                },
                                [_vm._v("List Features")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isVector && _vm.editMode == "layer"
                        ? _c(
                            "b-card",
                            { attrs: { sm: "" } },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName:
                                        "v-b-modal.modal-download-geojson-data",
                                      modifiers: {
                                        "modal-download-geojson-data": true,
                                      },
                                    },
                                  ],
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-secondary",
                                    size: "sm",
                                    title: "Download GeoJSON data",
                                  },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "download" } }),
                                  _vm._v("GeoJSON"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName:
                                        "v-b-modal.modal-download-gml-data",
                                      modifiers: {
                                        "modal-download-gml-data": true,
                                      },
                                    },
                                  ],
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-secondary",
                                    size: "sm",
                                    title: "Download GML data",
                                  },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "download" } }),
                                  _vm._v("GML"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName:
                                        "v-b-modal.modal-download-shape-data",
                                      modifiers: {
                                        "modal-download-shape-data": true,
                                      },
                                    },
                                  ],
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-secondary",
                                    size: "sm",
                                    title: "Download ESRI-Shape data",
                                  },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "download" } }),
                                  _vm._v("Shape"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName:
                                        "v-b-modal.modal-download-gpx-data",
                                      modifiers: {
                                        "modal-download-gpx-data": true,
                                      },
                                    },
                                  ],
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-secondary",
                                    size: "sm",
                                    title: "Download GPX data",
                                  },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "download" } }),
                                  _vm._v("GPX"),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isVector && _vm.editMode == "layer"
                        ? _c(
                            "b-card",
                            { attrs: { sm: "" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-primary",
                                    size: "sm",
                                    title: "Submit KLIC data",
                                  },
                                  on: { click: _vm.submitKlicUrl },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "upload" } }),
                                  _vm._v("KLIC"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-primary",
                                    size: "sm",
                                    title: "Submit Boring data",
                                  },
                                  on: { click: _vm.submitBoreLog },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "upload" } }),
                                  _vm._v("BoreLog"),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-primary",
                                    size: "sm",
                                    title: "Submit GIS data",
                                  },
                                  on: { click: _vm.submitGISData },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "upload" } }),
                                  _vm._v("GeoJSON"),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-card",
                        { attrs: { sm: "" } },
                        [
                          _vm.canBecomeOwner
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-warning",
                                    size: "sm",
                                    title: "Become owner of this layer",
                                  },
                                  on: { click: _vm.becomeOwner },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "sunrice" } }),
                                  _vm._v("become owner"),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.editMode == "layer"
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-warning",
                                    size: "sm",
                                    title: "Reset styles this layer",
                                  },
                                  on: { click: _vm.resetToDefaultLayerStyle },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "sunrice" } }),
                                  _vm._v("reset style"),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isEdited
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1",
                                  attrs: {
                                    variant: "outline-warning",
                                    size: "sm",
                                    title: "Reset styles of features",
                                  },
                                  on: {
                                    click: _vm.resetFeaturesToDefaultStyle,
                                  },
                                },
                                [
                                  _c("b-icon", { attrs: { icon: "sunrice" } }),
                                  _vm._v("reset features styles"),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-card",
                        { attrs: { sm: "" } },
                        [
                          _vm.editMode == "layer"
                            ? _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    "label-cols-sm": "5",
                                    "label-for": "layerOpacitySpinButton",
                                    sm: "",
                                    label: "matheid",
                                  },
                                },
                                [
                                  _c("b-form-spinbutton", {
                                    attrs: {
                                      id: "layerOpacitySpinButton",
                                      min: "0",
                                      max: "1",
                                      step: "0.1",
                                    },
                                    model: {
                                      value: _vm.myStyle.layerOpacity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.myStyle,
                                          "layerOpacity",
                                          $$v
                                        )
                                      },
                                      expression: "myStyle.layerOpacity",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isVector
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-cols-sm": "5",
                                        sm: "",
                                        label: "punt grootte",
                                      },
                                    },
                                    [
                                      _c("b-form-spinbutton", {
                                        attrs: {
                                          id: "pointRadiuSpinbut",
                                          min: "0",
                                          max: "100",
                                        },
                                        model: {
                                          value: _vm.myStyle.pointRadius,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.myStyle,
                                              "pointRadius",
                                              $$v
                                            )
                                          },
                                          expression: "myStyle.pointRadius",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-cols-sm": "5",
                                        sm: "",
                                        label: "hoek (graden)",
                                      },
                                    },
                                    [
                                      _c("b-form-spinbutton", {
                                        attrs: {
                                          id: "pointAngleSpinbut",
                                          min: "0",
                                          max: "360",
                                          step: "5",
                                          disabled:
                                            _vm.myStyle.pointType !== "arrow",
                                        },
                                        model: {
                                          value: _vm.myStyle.pointAngle,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.myStyle,
                                              "pointAngle",
                                              $$v
                                            )
                                          },
                                          expression: "myStyle.pointAngle",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    { attrs: { sm: "", label: "punt type" } },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          options: _vm.pointTypeOptions,
                                          size: "sm",
                                        },
                                        model: {
                                          value: _vm.myStyle.pointType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.myStyle,
                                              "pointType",
                                              $$v
                                            )
                                          },
                                          expression: "myStyle.pointType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { lg: "3" } },
                    [
                      _vm.isVector
                        ? _c(
                            "b-card",
                            { attrs: { sm: "" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "d-none d-lg-block",
                                  attrs: { label: "label kleur" },
                                },
                                [
                                  _c("b-form-radio-group", {
                                    key: _vm.update,
                                    attrs: {
                                      sm: "",
                                      stacked: "",
                                      "button-variant": "outline-primary",
                                      buttons: "",
                                      "html-field": "labelfield",
                                      id: "radio-group-2",
                                      options: _vm.colorOptions,
                                      disabled: !_vm.myStyle.isLabeled,
                                    },
                                    model: {
                                      value: _vm.myStyle.labelColor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.myStyle, "labelColor", $$v)
                                      },
                                      expression: "myStyle.labelColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "checkIsLabeled" },
                                  model: {
                                    value: _vm.myStyle.isLabeled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.myStyle, "isLabeled", $$v)
                                    },
                                    expression: "myStyle.isLabeled",
                                  },
                                },
                                [_vm._v("labels aan")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "d-lg-none",
                                  attrs: { sm: "", label: "label kleur" },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      size: "sm",
                                      disabled: !_vm.myStyle.isLabeled,
                                      "html-field": "labelfield",
                                      id: "select-group-line-color",
                                      options: _vm.colorOptions,
                                    },
                                    model: {
                                      value: _vm.myStyle.labelColor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.myStyle, "labelColor", $$v)
                                      },
                                      expression: "myStyle.labelColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isVector
                                ? _c(
                                    "b-form-group",
                                    { attrs: { sm: "", label: "label item" } },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          disabled: !_vm.myStyle.isLabeled,
                                          options: _vm.labelFieldOptions,
                                          size: "sm",
                                        },
                                        model: {
                                          value: _vm.myStyle.labelField,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.myStyle,
                                              "labelField",
                                              $$v
                                            )
                                          },
                                          expression: "myStyle.labelField",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                { attrs: { sm: "", label: "uitlijning" } },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      disabled: !_vm.myStyle.isLabeled,
                                      options: _vm.textAlignOptions,
                                      size: "sm",
                                    },
                                    model: {
                                      value: _vm.myStyle.textAlign,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.myStyle, "textAlign", $$v)
                                      },
                                      expression: "myStyle.textAlign",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("b-form-select", {
                                    attrs: {
                                      disabled: !_vm.myStyle.isLabeled,
                                      options: _vm.textYOffsetOptions,
                                      size: "sm",
                                    },
                                    model: {
                                      value: _vm.myStyle.labelYOffset,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.myStyle,
                                          "labelYOffset",
                                          $$v
                                        )
                                      },
                                      expression: "myStyle.labelYOffset",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "checkIsANumber",
                                    disabled: !_vm.myStyle.isLabeled,
                                  },
                                  model: {
                                    value: _vm.myStyle.isANumber,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.myStyle, "isANumber", $$v)
                                    },
                                    expression: "myStyle.isANumber",
                                  },
                                },
                                [_vm._v("als nummer")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                { attrs: { sm: "", label: "label grootte" } },
                                [
                                  _c("b-form-spinbutton", {
                                    attrs: {
                                      id: "labelFontSize",
                                      disabled: !_vm.myStyle.isLabeled,
                                      min: "0",
                                      max: "100",
                                    },
                                    model: {
                                      value: _vm.myStyle.labelFontSize,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.myStyle,
                                          "labelFontSize",
                                          $$v
                                        )
                                      },
                                      expression: "myStyle.labelFontSize",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { lg: "3" } },
                    [
                      _vm.isVector
                        ? _c(
                            "b-card",
                            { attrs: { sm: "" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "d-none d-lg-block",
                                  attrs: { sm: "", label: "lijn kleur" },
                                },
                                [
                                  _c("b-form-radio-group", {
                                    attrs: {
                                      stacked: "",
                                      buttons: "",
                                      size: "sm",
                                      id: "radio-group-line-color",
                                      "button-variant": "outline-primary",
                                      disabled:
                                        _vm.getProperty("strokeWidth") == 0,
                                      "html-field": "labelfield",
                                      options: _vm.colorOptions,
                                    },
                                    model: {
                                      value: _vm.myStyle.strokeColor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.myStyle,
                                          "strokeColor",
                                          $$v
                                        )
                                      },
                                      expression: "myStyle.strokeColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "d-lg-none",
                                  attrs: { sm: "", label: "lijn kleur" },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      size: "sm",
                                      disabled: _vm.myStyle.strokeWidth == 0,
                                      "html-field": "labelfield",
                                      id: "select-group-line-color",
                                      options: _vm.colorOptions,
                                    },
                                    model: {
                                      value: _vm.myStyle.strokeColor,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.myStyle,
                                          "strokeColor",
                                          $$v
                                        )
                                      },
                                      expression: "myStyle.strokeColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                { attrs: { sm: "", label: "lijn dikte" } },
                                [
                                  _c("b-form-spinbutton", {
                                    attrs: {
                                      id: "strokeWidthspinbut",
                                      min: "0",
                                      max: "100",
                                    },
                                    model: {
                                      value: _vm.myStyle.strokeWidth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.myStyle,
                                          "strokeWidth",
                                          $$v
                                        )
                                      },
                                      expression: "myStyle.strokeWidth",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                { attrs: { sm: "", label: "lijn stijl" } },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.strokeDashStyleOptions,
                                      size: "sm",
                                    },
                                    model: {
                                      value: _vm.myStyle.strokeDashstyle,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.myStyle,
                                          "strokeDashstyle",
                                          $$v
                                        )
                                      },
                                      expression: "myStyle.strokeDashstyle",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { lg: "3" } },
                    [
                      _vm.isVector
                        ? _c(
                            "b-card",
                            { attrs: { sm: "" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "d-none d-lg-block",
                                  attrs: { sm: "", label: "vulling kleur" },
                                },
                                [
                                  _c("b-form-radio-group", {
                                    attrs: {
                                      stacked: "",
                                      buttons: "",
                                      "button-variant": "outline-primary",
                                      size: "sm",
                                      disabled: !_vm.myStyle.showFills,
                                      "html-field": "labelfield",
                                      id: "radio-group-fill-color",
                                      options: _vm.colorOptions,
                                    },
                                    model: {
                                      value: _vm.myStyle.fillColor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.myStyle, "fillColor", $$v)
                                      },
                                      expression: "myStyle.fillColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "freeColor",
                                    sm: "",
                                    label: "vulling kleur",
                                    disabled: !_vm.myStyle.showFills,
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { type: "color" },
                                    model: {
                                      value: _vm.myStyle.fillColor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.myStyle, "fillColor", $$v)
                                      },
                                      expression: "myStyle.fillColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "d-lg-none",
                                  attrs: { sm: "", label: "vulling kleur" },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      size: "sm",
                                      disabled: !_vm.myStyle.showFills,
                                      "html-field": "labelfield",
                                      id: "select-group-fill-color",
                                      options: _vm.colorOptions,
                                    },
                                    model: {
                                      value: _vm.myStyle.fillColor,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.myStyle, "fillColor", $$v)
                                      },
                                      expression: "myStyle.fillColor",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.editMode == "feature" &&
                                      _vm.myObject.getGeometry().getType() ==
                                        "LineString",
                                    id: "checkIsFilled",
                                  },
                                  model: {
                                    value: _vm.myStyle.showFills,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.myStyle, "showFills", $$v)
                                    },
                                    expression: "myStyle.showFills",
                                  },
                                },
                                [_vm._v("vulling aan")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "checkStripes",
                                    disabled: !_vm.myStyle.showFills,
                                  },
                                  model: {
                                    value: _vm.myStyle.patternHorStripes,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.myStyle,
                                        "patternHorStripes",
                                        $$v
                                      )
                                    },
                                    expression: "myStyle.patternHorStripes",
                                  },
                                },
                                [_vm._v("gestreept")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "checkStripesDashed",
                                    disabled: !_vm.myStyle.patternHorStripes,
                                  },
                                  model: {
                                    value: _vm.myStyle.patternHorStripesDashed,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.myStyle,
                                        "patternHorStripesDashed",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "myStyle.patternHorStripesDashed",
                                  },
                                },
                                [_vm._v("gestreept-onderbroken")]
                              ),
                              _vm._v(" "),
                              _vm.editMode == "layer"
                                ? _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-cols-sm": "5",
                                        sm: "",
                                        label: "vulling matheid",
                                      },
                                    },
                                    [
                                      _c("b-form-spinbutton", {
                                        attrs: {
                                          id: "fillOpacitySpinButton",
                                          min: "0",
                                          max: "1",
                                          step: "0.1",
                                        },
                                        model: {
                                          value: _vm.myStyle.fillOpacity,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.myStyle,
                                              "fillOpacity",
                                              $$v
                                            )
                                          },
                                          expression: "myStyle.fillOpacity",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("modal-list-features"),
      _vm._v(" "),
      _c("modal-download-geojson-data"),
      _vm._v(" "),
      _c("modal-download-gml-data"),
      _vm._v(" "),
      _c("modal-download-gpx-data"),
      _vm._v(" "),
      _c("modal-download-shape-data"),
      _vm._v(" "),
      _c("modal-legend-graphic", { ref: "modalLegendGraphic" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }