var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: { id: "modal-list-features", title: "List Features" },
      on: { show: _vm.updateData },
    },
    [
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          fields: _vm.pointFields,
          items: _vm.pointData,
        },
      }),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          fields: _vm.lineFields,
          items: _vm.lineData,
        },
      }),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          fields: _vm.polygonFields,
          items: _vm.polygonData,
        },
      }),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          striped: "",
          hover: "",
          fields: _vm.otherFields,
          items: _vm.otherData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }