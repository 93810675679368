'use strict';

import {getWidth, getBottomLeft, getTopLeft} from 'ol/extent';
import {boundsNL} 		from './globals.js';
import {projectionNL} 	from './globals.js';
import {resolutionsNL} 	from './globals.js';

import {bbox as bboxStrategy} 	from 'ol/loadingstrategy';

import VectorTileSource from 'ol/source/VectorTile.js';
import VectorSource 	from 'ol/source/Vector.js';
import TileJSON 		from 'ol/source/TileJSON.js';
import XYZSource	 	from 'ol/source/XYZ.js';
import TileImage 		from 'ol/source/TileImage.js';
import ImageWMS 		from 'ol/source/ImageWMS';
import TileArcGISRest 	from 'ol/source/TileArcGISRest';
import TileWMS 			from 'ol/source/TileWMS';
import WMTS 			from 'ol/source/WMTS';
//import WMSServerType 	from 'ol/source/WMSServerType';

import GeoTIFF 			from 'ol/source/GeoTIFF.js';

import TileGrid 		from 'ol/tilegrid/TileGrid.js';
import WMTSTileGrid 	from 'ol/tilegrid/WMTS';
import {optionsFromCapabilities} from 'ol/source/WMTS';

import VectorTileLayer  from 'ol/layer/VectorTile.js';
import VectorLayer 		from 'ol/layer/Vector.js';
import TileLayer 		from 'ol/layer/Tile.js';
import ImageLayer 		from 'ol/layer/Image.js';
import WebGLTileLayer 	from 'ol/layer/WebGLTile.js';


import MVT 				from 'ol/format/MVT.js';
import WMSCapabilities 	from 'ol/format/WMSCapabilities';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import XML             from 'ol/format/XML';
import GML3             from 'ol/format/GML3';
import GML32            from 'ol/format/GML32';
import GML             from 'ol/format/GML';
import {WFS}            from 'ol/format.js';
import EsriJSON 		from 'ol/format/EsriJSON.js';

import Projection 		from 'ol/proj/Projection.js';
import {Translate, Select, DragBox, Draw, Modify, Snap}     from 'ol/interaction.js';
import {createBox}                                          from 'ol/interaction/Draw';
import Feature                                              from 'ol/Feature';
import {never, shiftKeyOnly, singleClick, platformModifierKeyOnly} from 'ol/events/condition';
//import RotateFeatureInteraction                             from 'ol-rotate-feature' //https://www.npmjs.com/package/ol-rotate-feature
import RotateFeatureInteraction                             from './ol-rotate-feature/interaction.js'

import TranslateEvent                                       from 'ol/interaction/Translate';
import TranslateEventType                                   from 'ol/interaction/Translate';

import {Point, LineString, Polygon}                         from 'ol/geom';

import {setDefaultVectorStyle} from './styleFunctions.js';
import {getDrawStyleFunction} from './styleFunctions.js';
import {getModifyStyleFunction} from './styleFunctions.js';
import {getSelectStyleFunction} from './styleFunctions.js';
import {getModify_StyleFunction} from './styleFunctions.js';
import {getRotate_StyleFunction} from './styleFunctions.js';

import {setFeatureStateInsert, setFeatureStateUpdate,setFeaturesStateUpdate} 	from './globals.js';
import {setFeatureStateDelete} 							from './globals.js';
import {FeatureStates} 									from './globals.js';
import {calculateCenter} 									from './GIS.js';

import {lessThan as lessThanFilter} from 'ol/format/filter';
import {and as andFilter} from 'ol/format/filter';
import {bbox as bboxFilter} from 'ol/format/filter';

export function createLayer ( layerData ) {
	//set defaults
	layerData.extent = 			layerData.extent 		|| boundsNL;
	layerData.projection = 		layerData.projection 	|| projectionNL;
	layerData.resolutions = 	layerData.resolutions 	|| resolutionsNL;
    layerData.format =  		layerData.format 		|| 'image/png',
	layerData.layerOpacity = 		layerData.layerOpacity 		|| 1;

	var layer;

	if ( layerData.type == 'tmslayer' ) {
		layer = createTMSLayer( layerData );
	} else if ( layerData.type == 'wmts' ) {
		layer= createWMTSLayer( layerData );
	} else if ( layerData.type == 'user' || layerData.type == 'wfs' ) {
		layer= createVectorLayer( layerData );
	} else if ( layerData.type == 'wms'  ) {
		layer= createWMSLayer( layerData );
	} else if ( layerData.type == 'image'  ) {
		layer= createImageLayer( layerData );
	} else if ( layerData.type == 'HisGIS'  ) {
		layer= createHisGISLayer( layerData );
	} else if ( layerData.type == 'ArcRest'  ) {
		layer= createArcRestLayer( layerData );
	} else if ( layerData.type == 'geotiff'  ) {
		layer= createGeoTIFFLayer( layerData );
	} else if ( layerData.type == 'vectorTile'  ) { //AKA pbf, todo; cannot be reprojected, Hisgis has Cross-Origin!
		layer= createVectorTileLayer( layerData );
	} 
	layer.set( 'id', layerData.id );
	layer.set( 'name', layerData.name );
	layer.set( 'type', layerData.type );
	layer.set( 'url', layerData.url );
	layer.set( 'groupname', layerData.groupname );
	layer.set( 'layerspecs', layerData.layerspecs );
	if ( layerData.usersspecs ) {
		layer.set( 'user', layerData.userspecs.firstname + ' ' + layerData.userspecs.lastname );
	} else {
		layer.unset( 'user' );
	}

	return layer;
}

function createTMSLayer ( layerData ) {
    var urlFunction = getTMSURLFunction( layerData );
	let source = new TileImage({
				cacheSize:		16, /* https://github.com/openlayers/openlayers/issues/8425 */
                extent: 		layerData.extent,
                format: 		layerData.extent,
                projection: 	layerData.projection,
                tileGrid:
                    new TileGrid({
                            extent: 		layerData.extent,
                            origin: 		[layerData.layerspecs.origlon,layerData.layerspecs.origlat],//  getBottomLeft( boundsNL ), // [boundsNL[0], boundsNL[1] ], //-285401.92, 22598.08],  TopLeft - upside down!
                            resolutions: 	layerData.resolutions
                    }),
                tileUrlFunction: urlFunction,
                style: 			'default',
                wrapX: 			false
            });
    let layer = new TileLayer ({
        opacity: layerData.layerOpacity,
        source: source
    });
	setTileLoaderNotify( layer, ["tileloadstart"], ["tileloadend","tileloaderror"] ) 
	return layer;
}

function createGeoTIFFLayer ( layerData ) {
	//7 feb 2023: changed TileLayer to WebGLTileLayer
	let source = new GeoTIFF({
		normalize: 		true,
		opaque:			false,
		convertToRGB:	'auto',
		interpolate:	true,
		sources: 		[
			 				//{ max: 255, url: 'test.tif' }
 							//{ max: 255, url: '/cgi-bin/proxy.cgi?url=https://bodemdata.nl/files/scanned_bodemkaart_cog.tif'}
 							{ max: 255, url: layerData.url }
			 				//{ url: 'https://sentinel-cogs.s3.us-west-2.amazonaws.com/sentinel-s2-l2a-cogs/36/Q/WD/2020/7/S2A_36QWD_20200701_0_L2A/TCI.tif' }
						],
				})
	let layer = new WebGLTileLayer({
      		opacity: 	layerData.layerOpacity,
    		source: 	source	
	})
	return layer
}

function createVectorTileLayer( layerData ) {
    let tileJsonSource = new TileJSON({
        /*url: '/cgi-bin/proxy.cgi?url=https://hisgis.nl/tiles/noord-holland.json',*/
		tileJSON: {
  				"tilejson": "2.2.0",
  				"tiles": ["/cgi-bin/proxy.cgi?url=https://hisgis.nl/tiles/noord-holland/{z}/{x}/{y}.pbf"],
  				"minzoom": 5,
  				"maxzoom": 16,
  				"bounds": [4.384507, 52.177550, 5.306211, 53.074125]
		},
        crossOrigin: 'anonymous'
      })
	let layer = new VectorTileLayer({ 
			source: tileJsonSource
	})
	return layer
}

function createWMTSLayer ( layerData ) {
	let layer = new TileLayer({
      		opacity: layerData.layerOpacity
	})
	setWMTSCapabilities( layer, layerData )
	return layer
}


function setWMTSCapabilities ( wmtsLayer, layerData ) {
    let url = layerData.layerspecs.url
    fetch ( url + ( url.includes('?') ? "" : "?" ) + '&request=getCapabilities&service=wmts' )
	.then(function (response) {
    		return response.text();
  	})
  	.then(function (text) {
		let parser = new WMTSCapabilities();
    	let capabilities = parser.read(text);
		if ( capabilities == null ) {
			map.get('app').$bvModal.msgBoxOk('Informatie over deze WTMS laag is niet beschikbaar (capabilities).')
			return
		}
  		let options = optionsFromCapabilities( 
			capabilities, 
			{
				layer: 		layerData.layerspecs.layer,
				matrixSet: 	layerData.layerspecs.matrixset
			}
		)
		wmtsLayer.setSource( new WMTS ( options  ))
		setTileLoaderNotify( wmtsLayer, ["tileloadstart"], ["tileloadend","tileloaderror"] ) 
  	})
}



function createImageLayer( layerData ) {
	let tileGrid = new TileGrid({
		extent		: layerData.extent,
        origin		: [boundsNL[0], boundsNL[1] ],
        resolutions	: layerData.resolutions
	});
	return new TileLayer({
            	opacity: layerData.layerOpacity,
    			source: new TileWMS({
      				url: 			'getImageWMS.php?id=' + layerData.layerspecs.id + "&layerid=" + layerData.id, 
					cacheSize:		16, /* https://github.com/openlayers/openlayers/issues/8425 */
      				params: {
						'LAYERS': 	'ms',
						'STYLES':	'',
						'FORMAT':	''
					},
					tileGrid:		tileGrid,
                    serverType:     layerData.layerspecs.ismapserver == "f" ? 'MAPSERVER' : null,
					projection:		'EPSG:28992',
					hidpi: 			typeof( printData ) !== "undefined" && printData.hires == "1" ? true: false
    			})
	});
	imageLayer.set ( "noOfTilesLoading", 0 );
	imageLayer.getSource().on( ["tileloadstart","imageloadstart"], function() { 
		imageLayer.set( "noOfTilesLoading", imageLayer.get ( "noOfTilesLoading" ) + 1);
		if ( imageLayer.get ( "noOfTilesLoading" ) == 1) {
			map.get('app').$refs.layersControl.$forceUpdate();
		}
	});
	imageLayer.getSource().on( ["tileloadend","tileloaderror","imageloadend", "imageloaderror"], function() { 
		imageLayer.set( "noOfTilesLoading", imageLayer.get ( "noOfTilesLoading" ) - 1);
		if ( imageLayer.get ( "noOfTilesLoading" ) == 0) {
			map.get('app').$refs.layersControl.$forceUpdate();
		}
	});
	return imageLayer;
}

function createHisGISLayer ( layerData ){
	return new ImageLayer({
                opacity: layerData.layerOpacity,
                source: new ImageWMS({
                    url:             'getHisGIS.php?hisgisurl=' + encodeURIComponent( layerData.layerspecs.url ),
                    params: {
                        'LAYERS':   layerData.layerspecs.wmslayers,
                        'STYLES':   layerData.layerspecs.styles,
                        'FORMAT':   layerData.layerspecs.format
                    },
                    projection:     'EPSG:28992',
                    ratio:          1
                })
            });
}

function createArcRestLayer ( layerData ){
	let tileGrid = new TileGrid({
		extent: 		layerData.extent,
        origin: 		[boundsNL[0], boundsNL[1] ], //-285401.92, 22598.08], 
        resolutions: 	layerData.resolutions
    });
	let arcLayer = new TileLayer({
                opacity: layerData.layerOpacity,
                source: new TileArcGISRest({
                    url:            layerData.layerspecs.url,
                    params: {
                        'LAYERS':   layerData.layerspecs.layers,
                        'STYLES':   layerData.layerspecs.styles,
                        'FORMAT':   layerData.layerspecs.format
                    },
                    tileGrid:       tileGrid,
                    projection:     'EPSG:28992',
                    hidpi:          typeof( printData ) !== "undefined" && printData.hires == "1" ? true: false
                })
            });
	arcLayer.set('infoFormat', "esriJSON" )
	let getInfoBound =  getArcFeatureInfo.bind( arcLayer );
    if ( ! arcLayer.controls ){
        arcLayer.controls  = [];
	}
    arcLayer.controls['info'] = {
                active:     false,
                getActive:  function() {
					return arcLayer.controls.info.active
				},
                setActive:  function( active ) {
                    arcLayer.controls.info.active = active;
                    if ( active ) {
                        map.on('singleclick',  getInfoBound  )
                    } else {
                        map.un('singleclick',  getInfoBound   );
						map.get('app').$refs.featureInfo.removeSelection()
                    }
                }
    }
	setTileLoaderNotify( arcLayer, ["tileloadstart"], ["tileloadend","tileloaderror"] ) 
	return arcLayer
}


function createWMSLayer( layerData ) {
	var wmsLayer;

   	let tileGrid = new TileGrid({
		extent: 		layerData.extent,
        origin: 		[boundsNL[0], boundsNL[1] ], //-285401.92, 22598.08], 
        resolutions: 	layerData.resolutions
    });

	let wmsParams = {
    	'LAYERS':   layerData.layerspecs.wmslayers,
       	'STYLES':   layerData.layerspecs.styles,
        'FORMAT':   layerData.layerspecs.format
	}
	if ( layerData.layerspecs.filter != '' ) {
		wmsParams['FILTER'] =  layerData.layerspecs.filter
	}
	if ( layerData.layerspecs.servicekey != '' ) {
		wmsParams['SERVICEKEY'] =  layerData.layerspecs.servicekey
	}
	if ( layerData.layerspecs.sldurl != '' ) {
		wmsParams['SLD'] =  layerData.layerspecs.sldurl
	}

	if ( layerData.layerspecs.issingletile == 'f' ){

            var proxy = "";
            if ( layerData.layerspecs.url.indexOf("url=") > - 1) //proxy?
            {
                proxy = layerData.layerspecs.url.substring(0, layerData.layerspecs.url.indexOf("url=")+4);
                layerData.layerspecs.url = layerData.layerspecs.url.substring( layerData.layerspecs.url.indexOf("url=")+4);
            }
            wmsLayer = new TileLayer({
                opacity: layerData.layerOpacity,
                source: new TileWMS({
                    url:            layerData.layerspecs.url,
					cacheSize:		16, /* https://github.com/openlayers/openlayers/issues/8425 */
                    params: 		wmsParams,
                    tileGrid:       tileGrid,
                    serverType:     layerData.layerspecs.ismapserver == "f" ? 'MAPSERVER' : null,
                    projection:     'EPSG:28992',
                    hidpi:          typeof( printData ) !== "undefined" && printData.hires == "1" ? true: false
                })
            });

            if ( proxy != "" ) {
                wmsLayer.getSource().setTileLoadFunction(
                    function ( tile, src ) {
                        tile.getImage().src = proxy + encodeURIComponent( src );
                    }
                );
            }
	} else {
		  	//if ( layerData.id == 22723 ){
                    //layerData.layerspecs.url += '&SLD=https://www.aardeopdekaart.nl/sld/zandinbanen.sld'
            //}
            wmsLayer = new ImageLayer({
                opacity: layerData.layerOpacity,
                source: new ImageWMS({
                    url:            layerData.layerspecs.url,
                    params: 		wmsParams,
                    projection:     'EPSG:28992',
                    ratio:          1,
                    serverType:     layerData.layerspecs.ismapserver == "f" ? 'MAPSERVER' : null,
                    hidpi:          typeof( printData ) !== "undefined" && printData.hires == "1" ? true: false,
                })
            });

	}
	setWMSInfoControl( wmsLayer, layerData.layerspecs.url );
	setTileLoaderNotify( wmsLayer, ["tileloadstart","imageloadstart"], ["tileloadend","tileloaderror","imageloadend","imageloaderror"] ) 
	return wmsLayer;
}

function createVectorLayer( layerData ) {
	let vectorLayer;
	var url;
 	var featureType;
	var myGml3Format;
	var source;
	//var geometryNameServer =  layerData.layerspecs ? (layerData.layerspecs.propertyname || "the_geom" ) : "the_geom";
	var featureNS = 	'http://www.tinyows.org/';
	var loaderFunction;
	var featureRequest;
	var projection = "EPSG:28992";

	if ( typeof layerData.isServerLayer === "undefined") {
		layerData.isServerLayer  = true;
	}
	if ( layerData.id == "11" ) {
		layerData.isServerLayer  = false;
	}

	if (layerData.type == "user") {
        	url =           'userdata.php';
        	featureType =   "userdata_" + layerData.id;
	} else {
		url = layerData.layerspecs.url;
    	if ( ! url.startsWith( "/cgi-bin" )) {
       	 	url = '/cgi-bin/proxy.cgi?url=' + url;
    	}
		featureType = layerData.layerspecs.featuretype;
	}

	myGml3Format = new GML3( {
       	featureNS: 		featureNS,
       	featureType: 	featureType,
 		srsName:		projection,
	});

	var thistype = layerData.type;

	if ( layerData.isServerLayer ) {
		source = new VectorSource({
			format: 		new WFS({
								gmlFormat:		myGml3Format,
 								schemaLocation:"http://www.opengis.net/wfs",
        						featureNS: 		featureNS,
        						featureType: 	featureType
							}),
			loader: 		loaderFunction,
			strategy: 		bboxStrategy,
			projection: 	layerData.projection 
		});
		source.set( 'wfstUrl', 		'userdata.php' ); 	
		source.set( 'featureType',	'userdata_' + layerData.id );
		source.set( 'featureNS',	featureNS );
	} else {
		source = new VectorSource({
			format: 		new WFS({
								gmlFormat:		GML3,
 								schemaLocation:"http://www.opengis.net/wfs",
								dataProjection: layerData.projection
							}),
			projection: 	layerData.projection
		});
	}

	vectorLayer = new VectorLayer ({
        source: 	source
    });

    let featurePrefix =	'tows'
	if ( featureType.includes(':') ) { //the prefix is in the featureType string
		featurePrefix = featureType.substring(0, featureType.indexOf(':'))
		featureType = featureType.substring( featureType.indexOf(':') + 1 )
	}

	if (layerData.type != "user") fetch(   url + ( url.includes('?') ? "&" : "?" ) + 'request=DescribeFeatureType&service=WFS&version=2.0.0&typeNames=' + featureType, 
		{
    		method: 'GET',
		}).then ( response => response.text() )
		.then( function ( text ) {
			//console.log( text )
			const parser = new DOMParser();
    		const xml = parser.parseFromString( text , "application/xml");
    		//console.log(xml);
			let nodes = xml.evaluate('/xsd:schema//xsd:element[@type="gml:GeometryPropertyType"]', xml, function(prefix){var ns = {  'xsd' : 'http://www.w3.org/2001/XMLSchema' }; return ns[prefix] || null;  }, XPathResult.ANY_TYPE,null);
			let el = nodes.iterateNext()
			if ( el != null ) {
				let geometryName = el.getAttribute('name') 
				console.log('geometry name is:' + geometryName )
				if (layerData.layerspecs !== null) {
					if (layerData.layerspecs.propertyname == null) {
						layerData.layerspecs.propertyname = geometryName;
					}
				}
			}
			//Iets mee doen? 
			//TODO: mnaak van loaderfunction (hieronder) een aparte functie. Dan hier ook toepassen zodat geometrie naam gebruikt wordt
		})

	var geometryNameServer =  layerData.layerspecs ? (layerData.layerspecs.propertyname || "the_geom" ) : "the_geom";

	loaderFunction = function( extent) {
    	if ( thistype == 'user' ) {
			featureRequest = new WFS().writeGetFeature({
        		srsName: 		projection,
        		featurePrefix: 	'tows',
        		featureTypes: 	[ featureType ],
        		outputFormat: 	'GML3'
			});
		} else {
			if (layerData.id == 21628 || layerData.id == 30363) {
				let bouwjaar = 1850;
				if (layerData.id == 30363) {
					bouwjaar = 1900
				}
				featureRequest = new WFS().writeGetFeature({
        			srsName: 		projection,
        			featurePrefix: 	'tows',
        			featureTypes: 	[ featureType ],
        			outputFormat: 	'GML3',
					geometryName:	geometryNameServer,
					filter:			andFilter(
									lessThanFilter('bouwjaar', bouwjaar ),
									bboxFilter( geometryNameServer, extent, projection)
								)
				});
			} else {
				featureRequest = new WFS().writeGetFeature({
        			srsName: 		projection,
        			featurePrefix: 	'tows',
        			featureTypes: 	[ featureType ],
        			outputFormat: 	'GML3',
					bbox: 			extent,
					geometryName:	geometryNameServer
				});
			}
		}
		vectorLayer.set('isGettingFeatures', true);
		if (map.get('app').$refs.layersControl ) { //it doesn't exist in printview
			map.get('app').$refs.layersControl.$forceUpdate();
		}
  		fetch( url, {
    		method: 'POST',
    		headers: { 'Content-Type': 'text/xml' },
			body: new XMLSerializer().serializeToString( featureRequest) 
		}).then ( response => response.text() )
		  .then ( 
			function( text ) { 
				var myWFS = new WFS( {
					gmlFormat: myGml3Format
				});
				var features = myWFS.readFeatures( 
					text, 
					{
						'dataProjection': projection,
        				featureNS: 		featureNS,
        				featureType: 	featureType,
						geometryName:	geometryNameServer
					}
				);
				if ( thistype == 'user' ) {
                	for (let feature of features ) {
                    	if ( source.getFeatureById( feature.getId() ) == null ) {
                       	 source.addFeatures( features )
                    	}
                	}
            	} else {
                	source.clear();
                	source.addFeatures( features );
            	}

				vectorLayer.set('isGettingFeatures', false);
				if ( ! map.get('app').isPrintView ) {
					map.get('app').$refs.layersControl.$forceUpdate();
				}
			}
		);
	}
	if (layerData.isServerLayer) {
		vectorLayer.getSource().setLoader( loaderFunction );
	}

	setDefaultVectorStyle( vectorLayer, layerData );

	vectorLayer.set('removeFeatures', removeFeatures.bind(vectorLayer) );

	createVectorEditControls ( vectorLayer );

	return vectorLayer;
}


function getTMSURLFunction ( layerData ) {
    //var rawURL = layerData.layerspecs.url + '1.0.0/' + layerData.layerspecs.layername + '/';
    var rawURL = layerData.layerspecs.url;
	var imageExt = layerData.layerspecs.imagetype;
    return  function ( coordinate ) {
        if (coordinate === null) {
            return undefined;
        }
        let z = coordinate[0];
        let x = coordinate[1];
        let y = -coordinate[2]-1;
        return rawURL + z + '/' + x + '/' + y + imageExt;
    }
}

function createVectorEditControls ( layer ) {
	var source = layer.getSource();
	layer.controls = {
		Point: 				new Draw({ source: source, type: 'Point', 		geometryName: 'the_geom' }),
		LineString: 		new Draw({ source: source, type: 'LineString', 	geometryName: 'the_geom' }),
		Polygon: 			new Draw({ source: source, type: 'Polygon', 	geometryName: 'the_geom', style: getDrawStyleFunction( layer ) }),
		Circle: 			new Draw({ source: source, type: 'Circle', 		geometryName: 'the_geom' }),
		Rectangle: 			new Draw({ source: source, type: 'Circle', 		geometryName: 'the_geom',  geometryFunction: createBox()} ), // Use this with the draw interaction and type: 'Circle' to return a box instead of a circle geometry.
		Modify: 			new Select( 	{layers: [ layer ], style: getModifyStyleFunction( layer ) }),
		EditAttribute: 		new Select( 	{layers: [ layer]}),
		info:	 			new Select( 	{layers: [ layer]}),
		Select: 			new Select( 	{layers: [ layer], style: getSelectStyleFunction( layer ) }),
		Del: 				new Select( 	{layers: [ layer]}),
		//Translate:			new Translate( 	{layers: [ layer]}),
		DragBox: 			new DragBox( {condition: platformModifierKeyOnly}), //https://openlayers.org/en/latest/examples/box-selection.html
		Snap: 				new Snap({ edge: true, vertex: true, source: source})
	}

	layer.controls['Translate'] = new Translate( {
		features: layer.controls['Select'].getFeatures() 
	})

	layer.controls[ 'modify_'] = new Modify({
        features: 			layer.controls.Modify.getFeatures(),
		deleteCondition: 	evt => { return shiftKeyOnly(evt) && singleClick(evt) },
		style:				getModify_StyleFunction( layer )
    });
	function ondrawend( event ) {
		if ( layer.get('isSaveable') ) {
			setFeatureStateInsert( event.feature );
			layer.set('isModified', true );
		}
		map.get('app').$refs.modalEditAttributes.edit( event.feature );
	}
	function setFeaturesStateModified( event ) {
		if ( layer.get('isSaveable') ) {
			//if ( event.features.getLength() > 1 ) { alert( 'error modifying, more than one feature.')}
			setFeaturesStateUpdate( event.features.getArray() );
			layer.set('isModified', true );
		}
	}
	layer.controls['Translate']. on( 'translateend', setFeaturesStateModified.bind ( layer ));
	layer.controls['Point'].	 on('drawend', ondrawend.bind( layer ));
	layer.controls['LineString'].on('drawend', ondrawend.bind( layer ));
	layer.controls['Polygon'].	 on('drawend', ondrawend.bind( layer ));
	layer.controls['Circle'].	 on('drawend', ondrawend.bind( layer ));
	layer.controls['Rectangle']. on('drawend', ondrawend.bind( layer ));

	layer.controls['info'].setActive( false )
	layer.controls['info'].on('select', function( event) {
		if ( event.selected.length ) {
			map.get('app').$refs.featureInfo.startRequest();
			map.get('app').$refs.featureInfo.show( event.selected[0], layer );
			//map.get('app').$refs.modalEditAttributes.edit( event.selected[0], this.getFeatures());
			//event.target.getFeatures().clear()
			//layer.set('isModified', true );
		}
	});
	layer.controls['EditAttribute'].on('select', function( event) {
		if ( event.selected.length ) {
			map.get('app').$refs.modalEditAttributes.edit( event.selected[0], this.getFeatures());
			event.target.getFeatures().clear()
			layer.set('isModified', true );
		}
	});

	layer.controls['modify_'].on('modifyend', (function ( event ) {
			if ( layer.get('isSaveable') && event.features.getLength() > 0 ) {
					//if ( event.features.getLength() > 1 ) { alert( 'error modifying, more than one feature.')}
					setFeaturesStateUpdate( event.features.getArray() );
					this.set('isModified', true );
			}
		}).bind( layer )

	 );

	layer.controls['DragBox'].set('selectedFeatures', layer.controls['Select'].getFeatures() );
	layer.controls['DragBox'].on ('boxend', function( e ) {
		let thisDragBox = e.target;
  		var rotation = map.getView().getRotation();
  		var oblique = rotation % (Math.PI / 2) !== 0;
		var selectedFeatures = thisDragBox.get('selectedFeatures');
		selectedFeatures.length = 0;
  		var candidateFeatures = oblique ? [] : selectedFeatures;
  		var extent = thisDragBox.getGeometry().getExtent();
  		source.forEachFeatureIntersectingExtent(extent, function(feature) {
    		candidateFeatures.push(feature);
  		});

  		if (oblique) {
    		var anchor = [0, 0];
    		var geometry = thisDragBox.getGeometry().clone();
    		geometry.rotate(-rotation, anchor);
    		var extent$1 = geometry.getExtent();
    		candidateFeatures.forEach(function(feature) {
      			var geometry = feature.getGeometry().clone();
      			geometry.rotate(-rotation, anchor);
      			if (geometry.intersectsExtent(extent$1)) {
        			selectedFeatures.push(feature);
      			}
    		});
		}
  	});

	layer.getSource().on( ['addfeature', 'removefeature'] , function ( e ) {
		if ( ! map.get('app').isPrintView ) {
			if ( e.target.getFeatures().length )  { //e.target is VectorSource
				map.get('app').$refs.drawingTools.renumberingIsEnabled = true 
			} else {
				map.get('app').$refs.drawingTools.renumberingIsEnabled = false 
			}
		}
	})

	layer.controls['Select'].getFeatures().on( ['add', 'remove'], function( e ) { //bind to the collection that is used by Select and DragBox(select) tool
		let count = e.target.getLength();
		if ( count == 2 ) {
			map.get('app').$refs.drawingTools.differenceIsEnabled = true
		} else {
			map.get('app').$refs.drawingTools.differenceIsEnabled = false
		}
		
		if ( count > 1 ) {
			map.get('app').$refs.drawingTools.unionIsEnabled = true

			let features = e.target.getArray()
			let type = features[0].getGeometry().getType()
			let i = 1
			for( ; i < features.length; i++ ){
				if ( features[i].getGeometry().getType() != type ) {
					map.get('app').$refs.drawingTools.unionIsEnabled = false
					break
				}
			}
			if ( i == features.length ) { //break was not called
				map.get('app').$refs.drawingTools.unionIsEnabled = true
			}

		} else {
			map.get('app').$refs.drawingTools.unionIsEnabled = false 
		}
		if ( count == 1 ) {
			map.get('app').$refs.drawingTools.bufferIsEnabled = true
			let type = e.target.getArray()[0].getGeometry().getType()
			if ( type == "MultiPoint" || type == "MultiLineString" || type == "MultiPolygon" || type == "GeometryCollection" ) {
				map.get('app').$refs.drawingTools.breakIsEnabled = true 
			} else if ( type == "LineString" ) {
				if ( e.target.getArray()[0].getGeometry().getLength() > 0 ) {
					map.get('app').$refs.drawingTools.gridIsEnabled = true
				}
			}
		} else {
			map.get('app').$refs.drawingTools.breakIsEnabled = false
			map.get('app').$refs.drawingTools.bufferIsEnabled = false
			map.get('app').$refs.drawingTools.gridIsEnabled = false
		}
		return
	}.bind( layer ));

	layer.controls.Rotate =	new RotateFeatureInteraction({
			features: 	layer.controls.Select.getFeatures(),
			anchor: 	[ 0, 0 ],
			angle: 		-90 * Math.PI / 180
		});
	layer.controls['Rotate'].on( 'rotateend', setFeaturesStateModified.bind ( layer ));

	layer.controls.Rectangle.on( 'boxend', function( evt ){
		var geom = evt.target.getGeometry();
		var feature = new Feature({'the_geom': geom });
		feature.setGeometryName('the_geom');
		source.addFeature(feature.clone()); // so we must clone  the geom, using feature clone
	});

	layer.controls.Del.setMap( map );
	let removeFeaturesBound = removeFeatures.bind( layer )
	layer.controls.Del.on('select',  function ( evt ) {
    	removeFeaturesBound( evt.selected );
    	evt.target.getFeatures().clear();
	});

}

function setWMSInfoControl ( wmsLayer, url ) {
    if ( ! url.startsWith( "/cgi-bin" )) {
        url = '/cgi-bin/proxy.cgi?url=' + url;
    }
	wmsLayer.set('isGettingCapabilities', true);
    fetch ( url + ( url.includes('?') ? "" : "?" ) + '&request=getCapabilities&service=wms' )
		.then( function (response) {
            return response.text();
        })
		.then( function( text) {
			wmsLayer.set('isGettingCapabilities', false);
    		var parser = new WMSCapabilities();
            var result = parser.read ( text ) ;
			if ( result == null ) {
				map.get('app').$bvModal.msgBoxOk('De WMS laag heeft geen opvraagbare gegevens.')
				return
			}
            var formats = result.Capability.Request.GetFeatureInfo.Format;
			var infoFormat = '';
            if ( formats.includes("aokgml" )) {
                infoFormat = "aokgml";
            } else if ( formats.includes("application/vnd.ogc.gml" )) {
                infoFormat = "application/vnd.ogc.gml";
            } else {
                infoFormat = formats[ 0 ];
            }
			wmsLayer.set('infoFormat', infoFormat );
            if ( ! wmsLayer.controls ){
            	wmsLayer.controls  = [];
			}
			let getWMSInfoBound =  getWMSInfo.bind( wmsLayer );
            wmsLayer.controls['info'] = {
                active:     false,
                getActive:  function() {
					return wmsLayer.controls.info.active
				},
                setActive:  function( active ) {
                    wmsLayer.controls.info.active = active;
                    if ( active ) {
                        map.on('singleclick',  getWMSInfoBound  )
                    } else {
                        map.un('singleclick',  getWMSInfoBound   );
						map.get('app').$refs.featureInfo.removeSelection()
                    }
                }
            }

			//let subLayers = wmsLayer.getProperties().source.getParams().LAYERS.split(',') 
			//let layerCaps = result.Capability.Layer.Layer.find( obj => { return obj.Name=== subLayers[0] })
			//let maxScaleDenominator = layerCaps.MaxScaleDenominator
			let maxScaleDenominator = getMaxestScaleDenominator( result.Capability, wmsLayer.getProperties().source.getParams().LAYERS.split(',')  )
			if ( maxScaleDenominator != null ) {
				wmsLayer.setMaxResolution( getResolutionFromScale( maxScaleDenominator ))
			}
			
        });
}

function getMaxestScaleDenominator( caps, subWMSLayers ) {
	let max = null
	subWMSLayers.forEach( subWMSLayer => {
		let layerCaps = caps.Layer.Layer.find( obj => { return obj.Name=== subWMSLayer })
		if ( layerCaps) {
			max = Math.max ( layerCaps.MaxScaleDenominator , max)
		}
	})
	return max
}

function getResolutionFromScale( scale ) {
	let dpi = 72  /*default for Mapserver, see: https://mapserver.org/mapfile/map.html*/
	let inchpermeter = 1 / 0.0254
	let meterperdot = 1 / ( dpi * inchpermeter )
	return Math.round( meterperdot * scale ) /*resolution in Openlayers is defined as meters per dot */
} 

function getArcFeatureInfo ( evt ) {
	//https://developers.arcgis.com/openlayers/query-and-edit/query-a-feature-layer-spatial/
	//TODO
	//const feature = new Feature({ geometry: new Point( evt.coordinate) })
	//const esriJSONfeature = new ol.format.EsriJSON().writeFeatureObject( feature, {
            //featureProjection: map.getView().getProjection()
    //})
	//GET url wget "https://geo.odmh.nl/arcgis/rest/services/Atlas/Atlas_Archeologie/MapServer/15/query?f=json&where=(1=1) AND (1=1)&returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=524382.8256223442,6792966.145734792,"spatialReference":{"wkid":102100,"latestWkid":3857}}&geometryType=esriGeometryPoint&inSR=102100&outFields=*&outSR=102100"
	//deze werkt: wget "https://www.dinoloket.nl/arcgis/rest/services/dinoloket/lks_gbo_rd/MapServer/identify?geometry=92555,447111&geometryType=esriGeometryPoint&tolerance=2&mapExtent=92000,447000,93000,458000&imageDisplay=256,256,96"

//	https://www.broloket.nl/standalone/rest/services/uitgifteloket_gdn/lks_gbo_rd_v1/MapServer/identify?f=json&returnFieldName=true&returnGeometry=true&returnUnformattedValues=false&returnZ=false&tolerance=10&imageDisplay=1596,811,96&geometry={"x":142673.69779620136,"y":463277.4734509401}&geometryType=esriGeometryPoint&sr=28992&mapExtent=138597.19610151247,460658.54444985394,146101.3445498671,464471.74269021454&layers=all:0
//  https://www.dinoloket.nl/arcgis/rest/services/dinoloket/lks_gbo_rd/MapServer" 
	let coords = evt.coordinate.toString()
	let serverUrl = this.get('layerspecs').url;
	let layerNo = this.get('layerspecs').layers;
	let url = serverUrl + '/identify?f=json&returnFieldName=true&returnGeometry=true&returnUnformattedValues=false&returnZ=false&tolerance=10&imageDisplay=1596,811,96&geometry={"x":142673.69779620136,"y":463277.4734509401}&geometryType=esriGeometryPoint&sr=28992&mapExtent=138597.19610151247,460658.54444985394,146101.3445498671,464471.74269021454&layers=all:0';
    let thisLayer = this;
	map.get('app').$refs.featureInfo.startRequest();
	fetch(url)
            .then(function (response) { return response.text() })
            .then(function (rawText ) {
					map.get('app').$refs.featureInfo.show( rawText, thisLayer );
            }.bind( this ));
}

/**
    evt -> call to WidgetInfo[ text ]
*/
function getWMSInfo ( evt ) {
    var viewResolution = /** @type {number} */ (map.getView().getResolution());
    var url = this.getSource().getFeatureInfoUrl(
                evt.coordinate,
                viewResolution,
                map.getView().getProjection(),
                {
                    'INFO_FORMAT'   :   this.get('infoFormat'),
                    'FEATURE_COUNT' :   10
                }
    );
    if (url) {
        if ( ! url.startsWith( "/cgi-bin" )) {
            url = '/cgi-bin/proxy.cgi?url=' + url;
        }
        let thisLayer = this;
		map.get('app').$refs.featureInfo.startRequest();
        fetch(url)
            .then(function (response) { return response.text() })
            .then(function ( rawText /*can be text,xml or gml*/) {
					map.get('app').$refs.featureInfo.show( rawText, thisLayer );
            }.bind( this ));
    }
}

/**
 *  VectorLayer, Object (Array or Collection) => None
 *  Makes features invisible by setting state 'delete' (if saveable data) or by removing it from data (if local data).
 *  function must be bond to layer -> this
*/ 
function removeFeatures ( featuresObject ) {
    let features = [];
    if ( featuresObject instanceof Array ) {
        features = featuresObject;
    } else {
        features = featuresObject.getArray();
    }

    let isSaveable = this.get('isSaveable');
    let source = this.getSource();

    for( let i = 0; i < features.length; i ++ ){
		let feature = features[ i ];
    	if ( isSaveable ) {
            if ( feature.get('state') == FeatureStates.INSERT  ) {
                source.removeFeature( feature ); //this feature was not yet in the DB; so erase it on client side
            }  else {
                setFeatureStateDelete( feature );  //this feature must be deleted in DB
        		this.set('isModified', true );
            }
        } else {
       	 	source.removeFeature( feature ); //client-only layer
    	}
    }
}


function setTileLoaderNotify( layer, startEvents, endEvents ) {
	layer.set ( "noOfTilesLoading", 0 );

	layer.getSource().on( startEvents, function() { 
		layer.set( "noOfTilesLoading", layer.get ( "noOfTilesLoading" ) + 1);
		if ( layer.get ( "noOfTilesLoading" ) == 1) {
			if ( ! map.get('app').isPrintView ) {
				map.get('app').$refs.layersControl.$forceUpdate();
			}
		}
	});
	layer.getSource().on( endEvents, function() { 
		layer.set( "noOfTilesLoading", layer.get ( "noOfTilesLoading" ) - 1);
		if ( layer.get ( "noOfTilesLoading" ) == 0) {
			if ( ! map.get('app').isPrintView ) {
				map.get('app').$refs.layersControl.$forceUpdate();
			}
		}
	});
}

