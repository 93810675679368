<template>
	<b-modal 
		@show="updateData"
		id="modal-list-features" 
		title="List Features">
 		<b-table striped hover :fields="pointFields" :items="pointData"></b-table>
 		<b-table striped hover :fields="lineFields" :items="lineData"></b-table>
 		<b-table striped hover :fields="polygonFields" :items="polygonData"></b-table>
 		<b-table striped hover :fields="otherFields" :items="otherData"></b-table>
  	</b-modal>
</template>
<script>

export default {
	data()  {
		return { 
			pointData		: [],
			pointFields 	: ['type', 'X', 'Y', 'label', 'description'],
			lineData		: [],
			lineFields 		: ['type', 'length', 'label', 'description'],
			polygonData		: [],
			polygonFields	: ['type', 'area', 'label', 'description'],
			otherData		: [],
			otherFields 	: ['type', 'label', 'description']
		}
	},
	methods: {
		updateData: function () {
			this.pointData = []
			this.lineData = []
			this.otherData = []
			let layer = this.$parent.myObject
			if ( layer== null )  {
				return
			}
			let features = layer.getSource().getFeatures()
			for (let i = 0; i < features.length; i++ ) {
				let geometry = features[i].getGeometry()
				let label = features[i].get('label')
				let description = features[i].get('description')
				let type = geometry.getType()
				let x = 0
				let y = 0
				let length = 0
				let area = 0
				if ( type == "Point" ) {
					x = geometry.getFirstCoordinate()[0];
					y = geometry.getFirstCoordinate()[1];
					this.pointData.push( { 
						"type" 			: type,
						"X"				: x,
						"Y"				: y,	
						'label'			: label,
						'description'	: description
					} )		
				} else if ( type == "LineString" ) {
					length = geometry.getLength();
					this.lineData.push( { 
						"type" 			: type,
						"length"		: length,
						'label'			: label,
						'description'	: description
					} )		
				} else if ( type == "Polygon" ) {
					area = geometry.getArea();
					this.polygonData.push( { 
						"type" 			: type,
						"area"			: area,
						'label'			: label,
						'description'	: description
					} )		
				} else {
					this.otherData.push( { 
						"type" 			: type,
						'label'			: label,
						'description'	: description
					} )		
				}

			}

		}
	}
}
</script>
<style>
</style>
